import {Button, Col, Container, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import socket from "../context/SocketIOInstance";
import {ArrowDownCircle, ArrowUpCircle, X} from "react-bootstrap-icons";
import {PostModal} from "./PostModal";
import {CategoryItemsModal} from "./CategoryItemsModal";
import {MessageModal} from "./MessageModal";

export interface ICategoryItem {
    name: string,
    price: number
}

export interface ICategory {
    name: string,
    items: Array<ICategoryItem>
}

interface IEditPanelState {
    data: Array<ICategory>,
    modalShow: boolean,
    postModalShow: boolean,
    modalCategoryIndex: number | undefined,
    isError: boolean,
    loaded: boolean,
    actualDate: string,
    disableSaveButton: boolean,
    showMessageModal: boolean,
    backupMessage: string,
    saveMessage: string,
}

const initialState: IEditPanelState = {
    data: new Array<ICategory>(),
    modalShow: false,
    postModalShow: false,
    modalCategoryIndex: undefined,
    isError: false,
    loaded: false,
    actualDate: "",
    disableSaveButton: false,
    showMessageModal: false,
    backupMessage: "",
    saveMessage: "",
}

const informationMessage = "Mise à jour des prix (Estelle)";

export const EditPanel = () => {
    const [state, setState] = useState<IEditPanelState>(initialState);

    useEffect(function onMount() {
        showMessageModal();
        socket.emit('retrieve_data');
        socket.on('data', (data: any) => {
            if (!data && !!localStorage) {
                const localData = localStorage.getItem("data");
                if (!!localData) data = JSON.parse(localData);
            }
            const items = data?.items ?? [];
            const actualDate = data?.date ?? new Date().toISOString().split("T")[0];
            setState((prevState): IEditPanelState => ({...prevState, data: items, actualDate, loaded: true}));
        });
    }, []);

    useEffect(function onDataChange() {
        const stringData = JSON.stringify(state.data);
        const disableSaveButton = state.data.length === 0 || stringData.includes('"name":""');
        setState((prevState): IEditPanelState => ({...prevState, disableSaveButton}));
    }, [state.data]);

    const getMessageHashCode = (message: string): number => {
        let hash = 0, i, chr;
        if (message.length === 0) return hash;
        for (i = 0; i < message.length; i++) {
            chr = message.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0;
        }
        return hash;
    }

    const showMessageModal = () => {
        if (!localStorage) return;
        const messageHashCode = getMessageHashCode(informationMessage);
        const localKey = "message_hashcode";
        const localMessageHashCode = JSON.parse(localStorage.getItem(localKey) ?? "0");
        if (messageHashCode === localMessageHashCode) return;
        localStorage.setItem(localKey, JSON.stringify(messageHashCode));
        setState((prevState): IEditPanelState => ({...prevState, showMessageModal: true}));
    }

    const saveData = () => {
        if (state.disableSaveButton) return;
        if (!state.data || state.data.length === 0) return setState((prevState): IEditPanelState => ({
            ...prevState,
            saveMessage: "Pas de données à sauvegarder",
            backupMessage: "",
            isError: true,
            disableSaveButton: true
        }));
        setState((prevState): IEditPanelState => ({
            ...prevState,
            saveMessage: "",
            backupMessage: "",
            isError: false,
            disableSaveButton: true
        }));
        if (!!localStorage) localStorage.setItem("data", JSON.stringify({date: state.actualDate, items: state.data}));
        socket.emit('save_data', state.data, state.actualDate);
        socket.on('save_status', (result) => {
            if (result) setState((prevState): IEditPanelState => ({
                ...prevState,
                disableSaveButton: false,
                saveMessage: "Sauvegarde effectuée"
            }));
            else setState((prevState): IEditPanelState => ({
                ...prevState,
                isError: true,
                disableSaveButton: false,
                saveMessage: "Erreur de sauvegarde"
            }));
            setTimeout(() => {
                setState((prevState): IEditPanelState => ({
                    ...prevState,
                    isError: false,
                    disableSaveButton: false,
                    saveMessage: "",
                }));
            }, 2000)
        })
    }

    const retrieveBackup = () => {
        socket.emit('retrieve_backup');
        socket.on('backup_status', (data) => {
            if (!data) return setState((prevState): IEditPanelState => ({
                ...prevState,
                isError: true,
                disableSaveButton: false,
                backupMessage: "Aucune sauvegarde trouvée"
            }));
            const items = data.items;
            const actualDate = data.date;
            setState((prevState): IEditPanelState => ({
                ...prevState,
                data: items,
                actualDate,
                isError: false,
                disableSaveButton: false,
                backupMessage: "Sauvegarde récupérée"
            }));
            setTimeout(() => {
                setState((prevState): IEditPanelState => ({
                    ...prevState,
                    isError: false,
                    disableSaveButton: false,
                    backupMessage: "",
                }));
            }, 2000)
        })
    }

    const addCategory = () => {
        let data = new Array<ICategory>(...state.data);
        data.push({name: "", items: []});
        setState((prevState): IEditPanelState => ({...prevState, data: data}));
    }

    const addElement = () => {
        if (state.modalCategoryIndex === undefined) return;
        let data = new Array<ICategory>(...state.data);
        data[state.modalCategoryIndex].items.push({name: "", price: 1});
        setState((prevState): IEditPanelState => ({...prevState, data: data}));
    }

    const removeCategory = (index: number) => {
        let data = new Array<ICategory>(...state.data);
        if (index > -1) data.splice(index, 1);
        setState((prevState): IEditPanelState => ({...prevState, data: data}));
    }

    const removeCategoryElement = (index: number) => {
        if (state.modalCategoryIndex === undefined) return;
        let data = new Array<ICategory>(...state.data);
        if (state.modalCategoryIndex > -1 && index > -1) data[state.modalCategoryIndex].items.splice(index, 1);
        setState((prevState): IEditPanelState => ({...prevState, data: data}));
    }

    const handleCategoryNameEdit = (index: number, newName: string) => {
        let data = new Array<ICategory>(...state.data);
        data[index].name = newName;
        setState((prevState): IEditPanelState => ({...prevState, data: data}));
    }

    const handleItemNameEdit = (index: number, newName: string) => {
        if (state.modalCategoryIndex === undefined) return;
        let data = new Array<ICategory>(...state.data);
        data[state.modalCategoryIndex].items[index].name = newName;
        setState((prevState): IEditPanelState => ({...prevState, data: data}));
    }

    const handleItemPriceEdit = (index: number, newPrice: number) => {
        if (state.modalCategoryIndex === undefined) return;
        let data = new Array<ICategory>(...state.data);
        data[state.modalCategoryIndex].items[index].price = newPrice;
        setState((prevState): IEditPanelState => ({...prevState, data: data}));
    }

    const handleSwipeUpCategory = (index: number) => {
        if (index >= state.data.length) return;
        let data = new Array<ICategory>(...state.data);
        let save = data[index - 1];
        data[index - 1] = data[index];
        data[index] = save;
        setState((prevState): IEditPanelState => ({...prevState, data: data}));
    }

    const handleSwipeDownCategory = (index: number) => {
        if (index < 0) return;
        let data = new Array<ICategory>(...state.data);
        let save = data[index + 1];
        data[index + 1] = data[index];
        data[index] = save;
        setState((prevState): IEditPanelState => ({...prevState, data: data}));
    }

    const handleSwipeUpItem = (index: number) => {
        if (state.modalCategoryIndex === undefined) return;
        let currentItems = new Array<ICategoryItem>(...state.data[state.modalCategoryIndex].items);
        if (index >= currentItems.length) return;
        let savedItem = currentItems[index - 1];
        currentItems[index - 1] = currentItems[index];
        currentItems[index] = savedItem;

        let data = new Array<ICategory>(...state.data);
        data[state.modalCategoryIndex].items = currentItems;

        setState((prevState): IEditPanelState => ({...prevState, data}));
    }

    const handleSwipeDownItem = (index: number) => {
        if (index < 0 || state.modalCategoryIndex === undefined) return;
        let currentItems = new Array<ICategoryItem>(...state.data[state.modalCategoryIndex].items);
        let savedItem = currentItems[index + 1];
        currentItems[index + 1] = currentItems[index];
        currentItems[index] = savedItem;

        let data = new Array<ICategory>(...state.data);
        data[state.modalCategoryIndex].items = currentItems;

        setState((prevState): IEditPanelState => ({...prevState, data}));
    }

    const handleShowCategoryItems = (index: number) => () => {
        setState((prevState): IEditPanelState => ({
            ...prevState,
            modalShow: true,
            modalCategoryIndex: index,
        }))
    }

    const handleHideCategoryItemsModal = () => setState((prevState): IEditPanelState => ({
        ...prevState,
        modalShow: false
    }));

    const renderCategory = (category: ICategory, index: number) => (
        <Col key={index} className={"mb-4 col-12"}>
            <div className={"w-100 d-flex align-items-center"}>
                <div className={"w-100 d-flex align-items-center mb-2"}>
                    <div className={"d-flex"}>
                        <X className={"text-danger cursor-pointer"} size={"40px"}
                           onClick={() => removeCategory(index)}/>
                    </div>
                    <div className={"d-flex flex-column w-100"}>
                        <Form.Control
                            type="text"
                            onChange={e => handleCategoryNameEdit(index, e.target.value)}
                            value={category.name}
                        />
                        <Button className={"w-100 mt-2"} variant={"secondary"} onClick={handleShowCategoryItems(index)}>
                            Voir les éléments
                        </Button>
                    </div>
                    <div className={"d-flex flex-column h-100 align-items-center ml-2 mr-1"}>
                        {index > 0 ?
                            <ArrowUpCircle
                                size={"25px"}
                                onClick={() => handleSwipeUpCategory(index)}
                                className={"text-white arrow mb-2"}
                            />
                            : null
                        }
                        {index < state.data.length - 1 ?
                            <ArrowDownCircle
                                size={"25px"}
                                onClick={() => handleSwipeDownCategory(index)}
                                className={"text-white arrow mt-2"}
                            />
                            : null
                        }
                    </div>
                </div>
            </div>
        </Col>
    )

    const addCategoryButton = (
        <Row>
            <Col>
                <Button variant="dark" className="w-100 my-3 border-0" onClick={addCategory}>
                    Ajouter une catégorie
                </Button>
            </Col>
        </Row>
    )

    const saveButton = (
        <Row>
            <Col className={"col-12"}>
                <Button disabled={state.disableSaveButton} variant="success" className="w-100 my-3 border-0"
                        onClick={saveData}>
                    Sauvegarder
                </Button>
            </Col>
            {!state.saveMessage ? null :
                <Col className={"col-12"}>
                    <h5 className={(state.isError ? "text-danger" : "text-success") + " text-center"}>
                        {state.saveMessage}
                    </h5>
                </Col>
            }
        </Row>
    )

    const publishButton = (
        <Row>
            <Col>
                <Button variant={"primary"} className="w-100 my-3 border-0"
                        onClick={() => setState((prevState): IEditPanelState => ({...prevState, postModalShow: true}))}>
                    Publier le menu du jour
                </Button>
            </Col>
        </Row>
    )

    const retrieveBackupButton = (
        <Row>
            <Col>
                <Button variant={"info"} className="w-100 my-3 border-0" onClick={retrieveBackup}>
                    Récupérer l'ancienne sauvegarde
                </Button>
            </Col>
            {!state.backupMessage ? null :
                <Col className={"col-12"}>
                    <h5 className={(state.isError ? "text-danger" : "text-info") + " text-center"}>
                        {state.backupMessage}
                    </h5>
                </Col>
            }
        </Row>
    )

    const actualDateInput = (<>
        <Col className={"text-center text-white mb-3"}><h3>Date affichée</h3></Col>
        <Col className="col-12 px-5">
            <Form.Control
                className="mb-4" value={state.actualDate}
                onChange={e => setState((prevState): IEditPanelState => ({...prevState, actualDate: e.target.value}))}
                type="date"
            />
        </Col>
    </>)

    const messageModal = (
        <MessageModal
            show={state.showMessageModal}
            onHide={() => setState((prevState): IEditPanelState => ({...prevState, showMessageModal: false}))}
            message={informationMessage}
        />
    )

    if (!state.loaded) return null;
    return (
        <Container fluid className={"flex-center full-height"}>
            <Row className="mt-3 mb-5">
                {actualDateInput}
                <Col className={"text-center text-white mb-3"}><h3>Menu</h3></Col>
                <Col className="col-12 text-center">
                    <Form>
                        <Row className={"position-relative"}>
                            {state.data.map(renderCategory)}
                        </Row>
                        {addCategoryButton}
                        {saveButton}
                        {publishButton}
                        {retrieveBackupButton}
                    </Form>
                </Col>
            </Row>
            {state.modalCategoryIndex !== undefined && !!state.data[state.modalCategoryIndex] ?
                <CategoryItemsModal
                    show={state.modalShow}
                    onHide={handleHideCategoryItemsModal}
                    category={state.data[state.modalCategoryIndex]}
                    addElement={addElement}
                    handleSwipeUpItem={handleSwipeUpItem}
                    handleSwipeDownItem={handleSwipeDownItem}
                    handleItemNameEdit={handleItemNameEdit}
                    handleItemPriceEdit={handleItemPriceEdit}
                    handleRemoveCategoryElement={removeCategoryElement}
                />
                :
                null
            }
            <PostModal
                data={state.data}
                date={state.actualDate}
                show={state.postModalShow}
                onHide={() => setState((prevState): IEditPanelState => ({...prevState, postModalShow: false}))}
            />
            {messageModal}
        </Container>
    )
}